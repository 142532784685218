<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="row">
        <div class="col-8 m-auto">
          <h1 class="text-center" style="margin-bottom: 50px;">How It Works</h1>
          <section>
            <strong>1. How to save search.</strong>
            <div style="text-align: center; margin-bottom: 10px;">
              <img src="../assets/img/how_to_works_step1.png" alt="" style="width: 100%;">
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam amet at aut blanditiis commodi consequatur culpa, dolores earum error est ipsam iusto laborum neque, nostrum obcaecati perspiciatis placeat quo repellat.</p>
          </section>
          <section>
            <strong>1. How to search by region.</strong>
            <div style="text-align: center; margin-bottom: 10px;">
              <img src="../assets/img/how_to_works_step2.png" alt="" style="width: 100%;">
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam amet at aut blanditiis commodi consequatur culpa, dolores earum error est ipsam iusto laborum neque, nostrum obcaecati perspiciatis placeat quo repellat.</p>
          </section>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {BaseLayout, SubHeader},
  mounted() {
    document.title = 'How It Works - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="scss" scoped>
  section {
    margin-bottom: 50px;

    strong {
      font-size: 18px;
      display: block;
      margin-bottom: 20px;
    }

    img {
      margin-bottom: 20px;
    }
  }
</style>